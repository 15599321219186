import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const Identity = ({ lang = "en" }) => {
    const query = useStaticQuery(graphql`query {
        menu: allMarkdownRemark (filter: { frontmatter: { name: {eq: "footer"} } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        list_description {
                            title
                            description
                            one_title
                            one_content
                            two_title
                            two_content
                            three_title
                            three_content
                        }
                    }
                }
            }
        }
    }`)
    const footer = query.menu.edges.filter(item => item.node.frontmatter.lang === lang)[0].node.frontmatter

    const [selectedIndex, setSelectedIndex] = useState(-1)

    const selectIndex = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1)
        } else {
            setSelectedIndex(index)
        }
    }

    return <div className="main__section main__section--50 main__section--50--sm">
        <div className="container">
            <div className="container__50">
                <h4>{footer.list_description.title}</h4>
                <div className="container__text__text">
                    {footer.list_description.description}
                </div>
            </div>
            <div className="container__50">
                <div className="item-list">
                    <div className={`item ${selectedIndex === 0 ? 'item--active' : ''}`}>
                        <div className="item__header" onClick={() => selectIndex(0)}>
                            <div className="item__header__title">{footer.list_description.one_title}</div>
                            <i className="icon-chevron-down"></i>
                        </div>
                        <div className="item__body">
                            <ReactMarkdown children={footer.list_description.one_content} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                    <div className={`item ${selectedIndex === 1 ? 'item--active' : ''}`}>
                        <div className="item__header" onClick={() => selectIndex(1)}>
                            <div className="item__header__title">{footer.list_description.two_title}</div>
                            <i className="icon-chevron-down"></i>
                        </div>
                        <div className="item__body">
                            <ReactMarkdown children={footer.list_description.two_content} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                    <div className={`item ${selectedIndex === 2 ? 'item--active' : ''}`}>
                        <div className="item__header" onClick={() => selectIndex(2)}>
                            <div className="item__header__title">{footer.list_description.three_title}</div>
                            <i className="icon-chevron-down"></i>
                        </div>
                        <div className="item__body">
                            <ReactMarkdown children={footer.list_description.three_content} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Identity
