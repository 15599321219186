import React, { useState } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import NewLayout from "../components/new_layout"
import CardPost from "../components/v2023/UI/Card/card-post"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"


const NewsPage = ({ data, pageContext }) => {
    const page = data.page
    const posts = data.posts.edges
    const lang = pageContext.langKey
    const baseUrl = lang === "en" ? "/news" : `/${lang}/actualidad`

    const [postSize, setPostSize] = useState(9)
    const [postListed, setPostListed] = useState(posts.slice(0, postSize))

    const showMorePosts = async () => {
        let s = postSize

        if (s < posts.length) {
            s += 9
            setPostSize(s)
            setPostListed(posts.slice(0, s))
        }
    }

    const elements = {
        li: ({node, ...props}) => {
            return <li {...props}><div>{props.children}</div></li>
        }
    }

    return (
        <NewLayout pageContext={pageContext} lang={pageContext?.langKey} translates={pageContext?.translates}>
            <Seo
                lang={pageContext.langKey}
                title={`${page.seo.title} ${pageContext.currentPage}`}
                description={`${page.seo.meta_description} ${pageContext.currentPage}`}
                translates={pageContext?.translates}
                alternateOtherLanguages={false}
                image={page.seo?.image_2?.localFile?.publicURL}
            />
            <main className="main">
                <div className="main__section main__section--100 white-section brown" >
                    <div className="container" data-aos="fade-up">
                        <h1 lang={lang} className="merriweather">{page.title}</h1>

                        <div className="grid-lg-3">
                            {postListed.map((post, index) => {
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.title}
                                    image={post.node.media_new}
                                    url={`${baseUrl}/${post.node.seo_url}`}
                                    date={post.node.publish_date}
                                    tag={post.node.category?.title} />
                            })}
                        </div>

                        {postListed.length < posts.length && <div className="container__see-more">
                            <button className="button button--xl button--secondary" onClick={showMorePosts}>{page.button}</button>
                        </div>}
                    </div>
                </div>
              
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="container__text">
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={elements} />
                            <div className="tile tile--white--nb">
                                <ReactMarkdown children={page.description_card} rehypePlugins={[rehypeRaw]} components={elements} />
                            </div>
                        </div>
                    </div>
                </div>

                <Certifications />

                <Identity lang={lang} />

            </main>
        </NewLayout>
    )
}

export default NewsPage

export const newsPageQuery = graphql`
    query ($langKey: String!, $dateFormat: String!) {
        page: strapi2022Actualidad (locale: {eq: $langKey}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            button
            description
            description_card
        }
        posts: allStrapiPosts (
            sort: { fields: [publish_date], order: [DESC] }
            filter: {
                locale: { eq: $langKey }
            }
        ) {
            edges {
                node {
                    id
                    media_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 1080
                                    width: 2080
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    lead
                    title
                    description
                    resume
                    content
                    category {
                        title
                    }
                    publish_date(formatString: $dateFormat, locale: $langKey)
                    seo_url
                }
            }
        }
    }
`
