import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Certifications = () => {
    return <div className="main__section">
        <div className="container container--np">
            <div className="grid-md">
                <StaticImage
                    src={"../../../new/resources/certifications/new/ft.png"}
                    alt="Ft"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/aifintech.png"}
                    alt="aifintech"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/regtech.png"}
                    alt="regtech"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/etica.png"}
                    alt="etica"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/techbehemoths.png"}
                    alt="techbehemoths"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/finnovating.png"}
                    alt="finnovating"
                    placeholder="blurred"
                />
                <StaticImage
                    src={"../../../new/resources/certifications/new/ecija.png"}
                    alt="ecija"
                    placeholder="blurred"
                />
            </div>
        </div>
    </div>
}

export default Certifications
